import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import classNames from 'classnames'
class BlogCards extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="flex flex-wrap">
        {posts &&
          posts.map(({ node: post }) => (
            // Collumn
            <div
              className={classNames(
                'w-full sm:w-1/2 lg:w-1/3 px-0 py-4 sm:p-4'
              )}
              key={post.id}
            >
              {/* Article */}
              <div
                className={`flex flex-col justify-between h-full rounded overflow-hidden shadow-lg dark:shadow-white pb-4 my-2 bg-white dark:bg-purple-800 ${
                  post.frontmatter.featuredpost ? 'border' : ''
                }`}
              >
                {post.frontmatter.featuredimage ? (
                  <div className="transition ease-in-out hover:opacity-75">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      }}
                    />
                  </div>
                ) : null}
                <header className="p-2 space-y-1 md:p-4">
                  <Link
                    className="no-underline hover:text-gray-900"
                    to={post.fields.slug}
                  >
                    <h1 className="block text-lg font-semibold dark:hover:text-purple-1000">
                      {post.frontmatter.title}
                    </h1>
                  </Link>
                  <span className="block text-xs">{post.frontmatter.date}</span>
                </header>
                <div className="flex p-3 text-sm text-gray-500 dark:text-gray-300 sm:text-base">
                  {post.excerpt}
                </div>
                {/* <footer className="flex justify-center p-2 leading-none md:p-4">
                  <Link to={post.fields.slug}>
                    <button className="inline-block px-5 py-3 text-base font-medium leading-snug text-white transition duration-150 ease-in-out bg-purple-900 border border-transparent rounded-md hover:bg-purple-800 dark:bg-purple-900 dark:hover:bg-purple-700 focus:outline-none focus:shadow-outline">
                      Lire →
                    </button>
                  </Link>
                </footer> */}
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogCards.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// add : limit: 2 after filter to limite 2 post
export default () => (
  <StaticQuery
    query={graphql`
      query BlogCardsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD/MM/YY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogCards data={data} count={count} />}
  />
)
