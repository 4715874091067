import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import BlogCards from '../../components/BlogCards'
import PageTitle from '../../components/PageTitle'
import SEO from '../../components/SEO'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Blog" />
        <PageTitle>Blog</PageTitle>
        <div className="w-full m-auto mb-0 prose text-justify dark:prose-white max-w-none md:mb-8">
          <h2>Bienvenue sur ma page blog !</h2> 
          <p>Vous trouverez ici mes tutos de développement, mes réflexions sur la vie de freelance et mes pensées sur le monde du web en général. N’hésitez pas à me <Link to="/contact">contacter</Link>, je suis toujours curieux d’avoir vos retours et de pouvoir échanger avec vous :)</p>
        </div>
        <section>
          <BlogCards />
        </section>
      </Layout>
    )
  }
}
